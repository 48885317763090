exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-commerce-tsx": () => import("./../../../src/pages/commerce.tsx" /* webpackChunkName: "component---src-pages-commerce-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gite-tsx": () => import("./../../../src/pages/gite.tsx" /* webpackChunkName: "component---src-pages-gite-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infrastructures-tsx": () => import("./../../../src/pages/infrastructures.tsx" /* webpackChunkName: "component---src-pages-infrastructures-tsx" */),
  "component---src-pages-pension-tsx": () => import("./../../../src/pages/pension.tsx" /* webpackChunkName: "component---src-pages-pension-tsx" */),
  "component---src-pages-transport-tsx": () => import("./../../../src/pages/transport.tsx" /* webpackChunkName: "component---src-pages-transport-tsx" */)
}

