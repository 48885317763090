import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import {
  Button,
  Grommet,
  Grid,
  Header,
  Nav,
  Footer,
  Box,
  Text,
  Anchor,
  Collapsible,
  ResponsiveContext,
} from "grommet";
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import { FacebookOption, Instagram, Menu as MenuIcon } from "grommet-icons";

const LinkStyle = styled(Link)`
  color: #dd9600;
  text-decoration: none;
  text-align: center;
`;

const Pages = [
  { label: "Présentation", href: "/" },
  { label: "Pensions", href: "/pension" },
  { label: "Infrastructures", href: "/infrastructures" },
  { label: "Commerce", href: "/commerce" },
  { label: "Transport", href: "/transport" },
  { label: "Gîte", href: "/gite" },
  { label: "Contact", href: "/contact" },
];

const Media = () => {
  return (
    <Box direction="row" gap="xxsmall" justify="center">
      <Anchor
        a11yTitle="Suivez nous sur facebook"
        href="https://www.facebook.com/lesecuriesducerf"
        icon={<FacebookOption color="brandSecondary" />}
      />
      <Anchor
        a11yTitle="Suivez nous sur instagram"
        href="https://instagram.com/lesecuriesducerf"
        icon={<Instagram color="brandSecondary" />}
      />
    </Box>
  );
};

const customTheme = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#1d1d1b",
      brandSecondary: "#dd9600",
      text: {
        light: "#1d1d1b",
      },
    },
  },
});

const Menu = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => setShowSidebar((prev) => !prev);
  return (
    <ResponsiveContext.Consumer>
      {(responsive) => {
        if (responsive === "small") {
          return (
            <Nav
              direction="column"
              justify="center"
              align="center"
              gap="none"
              style={{
                backgroundColor: "#1d1d1b",
                position: "sticky",
                top: "0px",
              }}
            >
              <Button
                label={<Text color="brandSecondary">Menu</Text>}
                icon={<MenuIcon color="brandSecondary" />}
                onClick={toggleSidebar}
              />
              <Collapsible open={showSidebar} direction="vertical">
                <Box gap="small" pad="medium">
                  {Pages.map((page) => {
                    return (
                      <LinkStyle
                        key={page.href}
                        to={page.href}
                        onClick={toggleSidebar}
                      >
                        {page.label}
                      </LinkStyle>
                    );
                  })}
                </Box>
              </Collapsible>
            </Nav>
          );
        }
        return (
          <Nav
            pad="medium"
            direction="row"
            justify="center"
            style={{ backgroundColor: "#1d1d1b" }}
          >
            {Pages.map((page) => {
              return (
                <LinkStyle key={page.href} to={page.href}>
                  {page.label}
                </LinkStyle>
              );
            })}
          </Nav>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

const BoxContainer = styled(Box)`
  min-height: 100vh;
`;

const HeaderContainer = styled(Header)`
  background-color: white;
  height: 20vh;
  width: 100%;
`;

const Layout = ({ children, ...props }) => {
  return (
    <Grommet theme={customTheme} full="min">
      <BoxContainer {...props}>
        <HeaderContainer background="white">
          <Grid
            columns={["1/4", "3/4"]}
            style={{ height: "100%", width: "100%" }}
          >
            <Box style={{ justifyContent: "center" }}>
              <Link
                to="/"
                style={{
                  maxHeight: "100%",
                  display: "flex",
                }}
              >
                <StaticImage
                  objectFit="contain"
                  backgroundColor="white"
                  src="../images/logo.svg"
                  alt="Logo des écuries du cerf"
                  style={{ maxHeight: "100%", width: "100%" }}
                />
              </Link>
            </Box>
            <StaticImage
              objectFit="cover"
              src="../images/paddock.jpg"
              alt="Les paddocks des écuries du cerf"
            />
          </Grid>
        </HeaderContainer>
        <Menu />
        <Box style={{ flexGrow: 1 }}>{children}</Box>
        <Footer background="brand" pad="small" margin={{ top: "large" }}>
          <Box
            align="center"
            direction="row"
            gap="xsmall"
            color="brandSecondary"
          >
            <Text alignSelf="center" color="brandSecondary" size="small">
              <Link
                style={{ color: customTheme.global.colors.brandSecondary }}
                to="/"
              >
                Les écuries du cerf
              </Link>
            </Text>
          </Box>
          <Media />
          <Text textAlign="center" size="xsmall" color="brandSecondary">
            ©Copyright
          </Text>
        </Footer>
      </BoxContainer>
    </Grommet>
  );
};

export default Layout;
